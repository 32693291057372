const prod = {
    catalogAPIurl: "http://gataria.example/catalog",
    imagesAPIurl: "http://gataria.example/images"
};

const dev = {
    catalogAPIurl: "https://gataria-catalog.rawdevops.io/catalog",
    imagesAPIurl: "https://gataria-images.rawdevops.io/images"
};

const config =
  process.env.REACT_APP_STAGE === "prod"
  ? prod
  : process.env.REACT_APP_STAGE === "dev"
    ? dev
    : dev;

export default {
    ...config
};
