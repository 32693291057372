import React, { useState, createContext } from "react";

export const CatsContext = createContext();
//Contexto para atualizar a lista de gatos toda vez que um novo gato é cadastrado ou excluido

export const CatsProvider = (props) => {
  const [reload, setReload] = useState(false);
  const [images, setImages] = useState([]);

  return (
    <CatsContext.Provider value={
      [images, setImages, reload, setReload]
    }>
      {props.children}
    </CatsContext.Provider>
  );
}
