import React, { useState, useEffect, useContext } from "react";
import envs from "../../config/envs.js";
import "./AllCatsImages.css";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { HiOutlineTrash } from "react-icons/hi2";
import axios from "axios";
import { CatsContext } from "../../contexts/CatsContext.js";
import toast, { Toaster } from 'react-hot-toast';
import Carousel from '../AddCats/Carousel.js'

const imagesAPIurl = envs.imagesAPIurl;

const catalogAPIurl = envs.catalogAPIurl;

function CatsImages(props) {
  const [reload, setReload] = useContext(CatsContext);
  const [imageUrl, setImageUrl] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const notifySuccess = () => toast.success('Gatinho excluído com sucesso')

  useEffect(() => {
    fetchCatImage();
  }, []);

  const fetchCatImage = () => {
    axios.get(`${imagesAPIurl}/getimg`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((response) => {
      setImageUrl(response.data[0].url);
    }).catch((error) => {
      if (error.response) {
        // O servidor respondeu com um status fora do intervalo 2xx
        console.log(error.response.data.error);
        const toastError = () => {
          toast.error(error.response.data.error)
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, 5000);
          });
        };
        toastError().then(() => {
          localStorage.removeItem("token");
          window.location.reload();
          window.location.href = "/login";
        })
      } else if (error.request) {
        // A requisição foi feita, mas não houve resposta
        console.log(error.request);
      } else {
        // Algo aconteceu na configuração da requisição que disparou um erro
        console.log('Error', error.message);
      }
    })
  };

  const handleDelete = (id) => {
    axios.delete(`${catalogAPIurl}/deletecats/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((response) => {
      handleClose();
      notifySuccess();
      setTimeout(() => {
        setReload(!reload);
      }, 2000);
    }).catch((error) => {
      if (error.response) {
        // O servidor respondeu com um status fora do intervalo 2xx
        console.log(error.response.data.error);
        const toastError = () => {
          toast.error(error.response.data.error)
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, 5000);
          });
        };
        toastError().then(() => {
          localStorage.removeItem("token");
          window.location.reload();
          window.location.href = "/login";
        })
      } else if (error.request) {
        // A requisição foi feita, mas não houve resposta
        console.log(error.request);
      } else {
        // Algo aconteceu na configuração da requisição que disparou um erro
        console.log('Error', error.message);
      }
    });
  };
  return (
    <div className="AllCatsImages">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '12px',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Excluir gatinho
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Deseja mesmo excluir o gatinho?
          </Typography>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px'
          }}>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button variant="contained" color="error" onClick={() => {
              handleDelete(props.cats.id);

            }}>Excluir</Button>
          </div>
        </Box>
      </Modal>
      <form>
        <ul>
          <Carousel isArray images={props.cats.image} />
          <li>
            <strong>Nome:</strong> {props.cats.name}
          </li>
          <li>
            <strong>Cidade:</strong> {props.cats.city}
          </li>
          <li>
            <strong>Estado:</strong> {props.cats.state}
          </li>
          <li>
            <strong>Telefone:</strong> +{props.cats.phone}
          </li>
          <li>
            <strong>Fêmeas para adoção:</strong> {props.cats.qtyFemale}
          </li>
          <li>
            <strong>Machos para adoção:</strong> {props.cats.qtyMale}
          </li>
          <li>
            <strong>Observações:</strong> {props.cats.notes}
          </li>

        </ul>
      </form>
      <button className="trash-button" onClick={handleOpen}>
        Excluir
        <HiOutlineTrash size={20} color="#000" />
      </button>
      <Toaster />
    </div>
  );
}

export default CatsImages;
