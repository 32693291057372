import React, { useState } from 'react'
import './Carousel.css'
import { GrNext, GrPrevious, GrTrash } from "react-icons/gr";
import noimage from "../../Images/no-image.jpg"

const Carousel = ({ images, isArray }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [catImage, setCatImage] = useState(noimage)

  const handleNext = (event) => {
    event.preventDefault()
    setCurrentIndex((currentIndex + 1) % images.length)
  }

  const handlePrevious = (event) => {
    event.preventDefault()
    setCurrentIndex((currentIndex - 1 + images.length) % images.length)
  }

  const Indicator = () => {
    return (
      <div className="indicator">
        {images.map((_, index) => (
          <span
            key={index}
            className={index === currentIndex ? '' : 'active'}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    )
  }

  const addCatsCarousel = () => {
    return (
      <div>
        <div style={{
          cursor: 'pointer',
          fontSize: '20px',
          position: 'absolute',
          backgroundColor: 'red',
          padding: 4,
          lineHeight: 1,
          borderRadius: 4
        }} onClick={() => {
          images.splice(currentIndex, 1)
          setCurrentIndex(0)
        }}>
          <GrTrash color='#000' />
        </div>
        {
          images.length > 0 && images ? <img src={URL.createObjectURL(images[currentIndex])} alt="Imagem do gatinho" /> : <img src={noimage} alt="Imagem do gatinho" />
        }
      </div>
    )
  }

  return (
    <>
      <div className="carousel">
        <>
          <button onClick={handlePrevious}>
            <GrPrevious color='#000' />
          </button>

          <div>
            {isArray ? <img src={images[currentIndex]} alt="Imagem do gatinho" /> : addCatsCarousel()}
            <Indicator />
          </div>

          <button onClick={handleNext}>
            <GrNext color='#000' />
          </button>
        </>
      </div>
    </>
  )
}

export default Carousel
