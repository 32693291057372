import React, { useState, useCallback, useContext } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./AddCats.css";
import envs from "../../config/envs.js";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import 'react-dropzone-uploader/dist/styles.css'
import Carousel from "./Carousel.js";
import { CatsContext } from "../../contexts/CatsContext.js";


const catalogAPIurl = envs.catalogAPIurl;
const imagesAPIurl = "https://gataria-images.rawdevops.io/images";

const states = [
  { value: "" },
  { value: "AC", label: "AC" },
  { value: "AL", label: "AL" },
  { value: "AP", label: "AP" },
  { value: "AM", label: "AM" },
  { value: "BA", label: "BA" },
  { value: "CE", label: "CE" },
  { value: "DF", label: "DF" },
  { value: "ES", label: "ES" },
  { value: "GO", label: "GO" },
  { value: "MA", label: "MA" },
  { value: "MS", label: "MS" },
  { value: "MG", label: "MG" },
  { value: "PA", label: "PA" },
  { value: "PB", label: "PB" },
  { value: "PR", label: "PR" },
  { value: "PE", label: "PE" },
  { value: "PI", label: "PI" },
  { value: "RJ", label: "RJ" },
  { value: "RN", label: "RN" },
  { value: "RS", label: "RS" },
  { value: "RO", label: "RO" },
  { value: "RR", label: "RR" },
  { value: "SC", label: "SC" },
  { value: "SP", label: "SP" },
  { value: "TO", label: "TO" },
];
const imagesUrl = [];


function RegisterCats() {
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [qtyFemale, setQtyFemale] = useState("");
  const [qtyMale, setQtyMale] = useState("");
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");
  const [images, setImages] = useState([]);
  // const [imagesUrl, setImagesUrl] = useState([]);

  const user = localStorage.getItem('username')
  const token = localStorage.getItem("token");

  // const [images, setImages] = useContext(CatsContext);

  const onChangeCallback = useCallback((event, setter) => {
    setter(event.target.value);
  }, []);

  const onFileChange = (event) => {
    if (event.target.files.length > 5 || images.length > 4) {
      toast.error('Você só pode enviar até 5 imagens');
      setImages([]);
      event.target.value = '';
    } else {
      setImages([...images, ...event.target.files])
    }
  }

  const onSubmitHandler = async (event) => {
    toast.loading('Cadastrando gatinho...');
    event.preventDefault();
    const results = await Promise.all(images.map(async (image) => {
      const res = await axios.post(`${imagesAPIurl}/uploadImg`, { image, 'username': user }, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      const res2 = await axios.post(`${imagesAPIurl}/getObj`, { key: res.data, username: user }, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      const imgsResponse = res2.data.imageUrl;
      imagesUrl.push(imgsResponse);
      return imgsResponse; // Retorne apenas a URL da imagem individual
    }))

    const res3 = await axios.post(`${catalogAPIurl}/registercats`, {
      image: results,
      name,
      city,
      state,
      qtyFemale,
      qtyMale,
      phone,
      notes
    },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    toast.dismiss();
    toast.success('Gatinho cadastrado com sucesso!');
    setName("");
    setCity("");
    setState("");
    setQtyFemale("");
    setQtyMale("");
    setPhone("");
    setNotes("");
    setImages([]);
    imagesUrl.length = 0;
  }

  return (
    <div className="AddCats">
      <form onSubmit={onSubmitHandler}>
        <h2>Cadastro de Gatinhos</h2>
        <div className="Line">
          <div className="Column">
            <label>Imagem</label>
            <input type="file" name="image" onChange={onFileChange} required multiple></input>
            <Carousel images={images} />
            <label>Seu nome</label>
            <input
              type="text"
              name="name"
              value={name}
              // onChange={(event) => setName(event.target.value)}
              onChange={(event) => onChangeCallback(event, setName)}
              required
            ></input>
          </div>
          <div className="Column">
            <label>Cidade</label>
            <input
              type="text"
              name="city"
              value={city}
              onChange={(event) => setCity(event.target.value)}
              required
            ></input>
          </div>
          <div className="Column">
            <label>Estado</label>
            <select
              name="state"
              value={state}
              onChange={(event) => setState(event.target.value)}
              required
              className="InputState"
            >
              {states.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="Column">
            <label>Fêmeas para adoção</label>
            <input
              type="text"
              name="qtyFemale"
              value={qtyFemale}
              onChange={(event) => setQtyFemale(event.target.value)}
              required
            ></input>
          </div>
          <div className="Column">
            <label>Machos para adoção</label>
            <input
              type="text"
              name="qtyMale"
              value={qtyMale}
              onChange={(event) => setQtyMale(event.target.value)}
              required
            ></input>
          </div>
          <div className="Column">
            <label>Telefone</label>
            <PhoneInput
              country={"br"}
              onlyCountries={["br"]}
              value={phone}
              onChange={(value) => setPhone(value)}
              inputProps={{
                name: "phone",
                required: true,
              }}
              className="InputPhone"
            />
          </div>
          <div className="Column">
            <label>Observações</label>
            <input
              placeholder="Ex: idade, cor, vacinas, etc.. "
              type="text"
              name="notes"
              value={notes}
              onChange={(event) => setNotes(event.target.value)}
              required
            ></input>
          </div>
        </div>
        <button type="submit">Adicionar</button>
      </form>
      <Toaster />
    </div>
  );
}

export default RegisterCats;
