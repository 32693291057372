import React from 'react'
import './Analytics.css'

//-- -- -- TODO -- -- --//
//Create table and graph for total users for admin
//Create total cats graph - female / male
//Create total accesses graph
//Create graph cats per region graph

const AnalyticsPage = () => {
  const MockData = [
    {
      title: 'Usuários',
      value: '87'
    },
    {
      title: 'Gatos',
      value: '282'
    },
    {
      title: 'Acessos',
      value: '647'
    }
  ]

  const Box = ({ title, value }) => {
    return (
      <div className='box'>
        <h4>{value}</h4>
        <h3>{title}</h3>
      </div>
    )
  }

  return (
    <>
      <h1>Analytics</h1>
      <div className='boxes'>
        {MockData.map((data, index) => (
          <Box key={index} title={data.title} value={data.value} />
        ))}
      </div>
      <h2>Gatos por região</h2>
      <div className='graph'>
        <img src='https://via.placeholder.com/800x500' alt='Graph' />
      </div>
    </>
  )
}

export default AnalyticsPage