import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { CatsProvider } from "./contexts/CatsContext";
import { UsersProvider } from "./contexts/UsersContext";
ReactDOM.render(
  <CatsProvider>
    <UsersProvider>
      <App />
    </UsersProvider>
  </CatsProvider>,
  document.getElementById("root")
);