import React from "react";
import "./index.css";
import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { IoIosEyeOff, IoIosEye } from "react-icons/io";
import toast, { Toaster } from 'react-hot-toast';

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory()

  const handleLogin = (e) => {
    const apibase = 'https://gataria-users.rawdevops.io/'
    e.preventDefault();
    axios
      .post(`${apibase}auth/login`, {
        identifier: email,
        password: password,
      }).then((response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', response.data.user.username);
        history.push("/")
      }).catch((error) => {
        console.log(error)
        let notify = () => toast.error('Email ou senha incorretos')
        notify()
      })
  }

  return (
    <div className="LoginPage">
      <h1>Login</h1>
      <form onSubmit={handleLogin} className="formLogin">
        <label htmlFor="email">Email ou usuário</label>
        <input onChange={(e) => {
          setEmail(e.target.value);
        }} type="text" id="email" name="email" />
        <label htmlFor="password">Senha</label>
        <div className="PasswordWrapper">
          <input onChange={(e) => {
            setPassword(e.target.value);
          }} type={isVisible ? 'text' : 'password'} id="password" name="password" />
          <button className="EyeButton" type="button" onClick={() => setIsVisible(!isVisible)}>
            {
              isVisible ? <IoIosEye size={25} /> : <IoIosEyeOff size={25} />
            }
          </button>
        </div>
        <button className="buttonSubmit" type="submit">Entrar</button>
        <a href="/register">
          <button type="submit" className="buttonRegister">Cadastrar</button>
        </a>
        <Toaster />
      </form>
    </div>
  );
}
